import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de color marró més o manco fosc en forma d’orella amb un dels costats no soldat que pot fer fins a 6 cm d’altura i que creix en grups de nombrosos individus.El peu és blanquinós més o manco rudimentari. Les espores són el·líptiques, llises, de 15-18 x 7-9 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      